import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';

const styles = css`
  .header {
    position: relative;
    color: #107db5;
    color: oklab(0.61 -0.08 -0.21);
    text-align: center;
    margin-top: 70px;
    padding: 10vh 0;
    width: 800px;
    margin: 0 auto;
    max-width: 100%;

    & img {
      position: relative;
      z-index: 1;
      border-radius: 100%;
      max-height: 200px;
      animation: float 8s ease-in-out infinite;
    }

    & svg {
      stroke: currentColor;
      height: 10vh;
      width: 10vh;
      position: absolute;
      filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.4));

      &:nth-child(2) {
        left: 2%;
        top: 2%;
        transform: rotate(10deg);
      }

      &:nth-child(3) {
        left: 20%;
        top: 20%;
        transform: rotate(-20deg);
      }
      &:nth-child(4) {
        left: 90%;
        top: 50%;
        transform: rotate(45deg);
      }
      &:nth-child(5) {
        left: 24%;
        top: 70%;
        transform: rotate(-45deg);
      }
      &:nth-child(6) {
        left: 74%;
        top: 70%;
        transform: rotate(45deg);
      }
      &:nth-child(7) {
        left: 0%;
        top: 50%;
        transform: rotate(25deg);
      }
      &:nth-child(8) {
        left: 90%;
        top: 2%;
        transform: rotate(-5deg);
      }
      &:nth-child(9) {
        left: 71%;
        top: 35%;
        transform: rotate(17deg);
      }

      &:nth-child(10) {
        left: 45%;
        top: -10%;
        transform: rotate(-13deg);
      }
      &:nth-child(11) {
        left: 67%;
        top: -5%;
        transform: rotate(3deg);
      }
    }
  }

  @keyframes float {
    0% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
    50% {
      box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
      transform: translatey(-16px);
    }
    100% {
      box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
  }
`;

function BeatingHeart(props) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => (
        <div className={styles.header}>
          <img src={data.polypane.publicURL} alt="" width={200} height={200} importance="high" loading="eager" />

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-bulb"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7" />
            <path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />
            <line x1="9.7" y1="17" x2="14.3" y2="17" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-heart"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-accessible"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="9" />
            <path d="M10 16.5l2 -3l2 3m-2 -3v-2l3 -1m-6 0l3 1" />
            <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-device-tablet"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="5" y="3" width="14" height="18" rx="1" />
            <circle cx="12" cy="17" r="1" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-device-mobile"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="7" y="4" width="10" height="16" rx="1" />
            <line x1="11" y1="5" x2="13" y2="5" />
            <line x1="12" y1="17" x2="12" y2="17.01" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-device-laptop"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="3" y1="19" x2="21" y2="19" />
            <rect x="5" y="6" width="14" height="10" rx="1" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-device-desktop"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="4" width="18" height="12" rx="1" />
            <line x1="7" y1="20" x2="17" y2="20" />
            <line x1="9" y1="16" x2="9" y2="20" />
            <line x1="15" y1="16" x2="15" y2="20" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-eye"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="2" />
            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" />
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-layout-cards"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="4" y="4" width="6" height="16" rx="2" />
            <rect x="14" y="4" width="6" height="10" rx="2" />
          </svg>
          <svg
            id="i-inspectelement"
            viewBox="0 0 24 24"
            width="44"
            height="44"
            fill="none"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M 8,20 H 6 C 5,20 4,19 4,18 4,14 4,10 4,6 4,5 5,4 6,4 h 12 c 1,0 2,1 2,2 v 2" />
            <path fill="currentColor" strokeWidth="0" d="m 21,14 -4,2 4,4 -1,1 -4,-4 -2,4 -3,-10 z" />
          </svg>
        </div>
      )}
    />
  );
}

export default BeatingHeart;

const detailsQuery = graphql`
  query BlackFridayHeaderQuery {
    polypane: file(relativePath: { eq: "images/icon.png" }) {
      publicURL
    }
  }
`;
