import { css } from 'astroturf';
import React, { Component } from 'react';

import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import Layout from '../../layout/index-noheader';

import BlackFridayHeader from '../../components/black-friday/header';

const styles = css`
  .wrap {
    position: relative;
    padding: 1rem;
    max-width: 700px;
    color: rgb(10, 21, 48);
    margin: 2rem 1rem;
    z-index: 1;

    @media (min-width: 40rem) {
      margin: 0rem auto;

      &::before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 99%;
        height: 99%;
        border-radius: 1rem;
        background: linear-gradient(to right bottom, rgb(114, 198, 239), rgb(0, 78, 143));
        transform: rotate(2deg);
        transform-origin: 40% 70%;
        box-shadow:
          rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
          rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
          rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
      }
    }
  }
  .bg {
    position: relative;
    z-index: 1;

    @media (min-width: 40rem) {
      box-shadow:
        rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
      background: #fff;
      border-radius: 1rem;
      padding: 3rem 3rem 2rem;
      @media (forced-colors: active) {
        border: 1px solid;
      }
    }
  }

  .punchout {
    background: linear-gradient(135deg, #2c82ad, ease-in-out, #67b26f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
`;

/* !!!!!!!! */
/* !!!!!!!! */
/* !!!!!!!! */
/* REMINDER: there is a redirect in gatsby-node.js for this page */


class Page extends Component {
  constructor() {
    super();

    this.state = {
      priceYearly: true,
    };
  }

  togglePricing = (value) => this.setState({ priceYearly: value });

  render() {
    return (
      <Layout background="rgb(53, 53, 70)">
        <SEO
          title="Convince your manager to get a Polypane subscription"
          description="opy and paste this letter and send it to your manager if you need to convince them to get a Polypane subscription."
          keywords={['']}
          pathname={this.props.location.pathname}
          ogFileName="blackfriday"
          ogCustomPath="/og-custom/"
        />
        <PageHeader nobg overflow>
          <BlackFridayHeader />
          <h1 style={{ color: '#fff', filter: 'drop-shadow(0px 3px 0px #000)' }}>
            Why we need the ✨ Polypane <span className={styles.punchout}>Black Friday</span> Deal ✨
          </h1>
          <h2
            style={{
              color: '#fff',
              fontWeight: 800,
              filter: 'drop-shadow(0px 3px 0px #000)',
              fontSize: 'clamp(14px, 14px + 2vw,24px)',
            }}
          >
            Need help convincing your employer?
            <br /> Copy and paste this letter and send it to them.
          </h2>
          <p style={{ color: '#fff' }}>
            At this time of year, most managers are looking what to do with the remainder of their yearly budgets and
            with the black friday deal, you can make a strong case.
          </p>
        </PageHeader>

        <div className="page" style={{ maxWidth: '60rem' }}>
          <div className={styles.wrap}>
            <div className={[styles.bg, 'childPadding'].join(' ')}>
              <p>Hey [manager],</p>

              <p>
                I've been trying out this awesome dev tool I've been trying out -{' '}
                <a href="https://polypane.app">Polypane</a>, and I'd like to request a license for it. Here's why it
                would be helpful:
              </p>

              <ul>
                <li>It's a desktop browser based on Chrome but made for developers.</li>
                <li>It lets you test multiple screen sizes simultaneously, saving time and effort.</li>
                <li>
                  It offers dozens of in-app accessibility checks and suggestions, helping us build high-quality and
                  accessible sites.
                </li>
                <li>Even better, the Polypane team is responsive to feedback and releases new versions each month.</li>
              </ul>

              <p>
                They're running a <a href="https://polypane.app/black-friday/">Black Friday deal</a> right now - 25% off
                a subscription for me or the whole team. I'm convinced this will save us a ton of time and make our
                development process way smoother.
              </p>

              <p>
                I'd love to get a subscription. If you're okay with it, we can have finance set up the billing, or I can
                take care of it with your green light. Their Black Friday deal is at{' '}
                <a href="https://polypane.app/black-friday/">https://polypane.app/black-friday/</a>, if you want to
                check it out.
              </p>

              <p>What do you think?</p>

              <p>
                Thanks!
                <br />
                <em>[Your name]</em>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Page;
